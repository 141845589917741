<template>
  <div class="appMissionDetails mx-3">
    <!-- <div class=" text-xl text-[#806157] font-bold mb-4">
      Mission Title: <span class="text-m text-[#806157] font-normal">{{ title }}</span>
    </div> -->
    <p class="text-xl text-[#806157] font-bold mb-1">{{ title }}</p>

    <!-- <div class="text-xl text-[#806157] font-bold mb-4">
      Mission Subtitle: <span class="text-m text-[#806157] font-normal">{{ description || 'No description' }}</span>
    </div> -->
    <p class="text-xl text-[#806157] mb-12">
      {{ description || "No description" }}
    </p>
    <!-- <div
      class="text-sm text-center font-semibold w-[70%] mx-auto rounded break-words py-2 px-2 mt-1"
      style="box-shadow: rgba(0, 0, 0, .2) 0 0 5px;"
    >
      {{ description || 'No description' }}
    </div> -->
    <!-- <div class="text-xl text-[#806157] font-bold mb-4">
      Mission Date: <span class="text-m text-[#806157] font-normal">{{missionDateFormat}}</span>
    </div> -->
    <h2 class="text-xl text-[#806157] font-bold mb-2">Mission Time Info</h2>
    <p class="mb-1">
      <svg
        class="mb-1"
        style="display: inline-block"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
      >
        <path
          fill="#806157"
          d="M8 14q-.425 0-.712-.288T7 13q0-.425.288-.712T8 12q.425 0 .713.288T9 13q0 .425-.288.713T8 14Zm4 0q-.425 0-.712-.288T11 13q0-.425.288-.712T12 12q.425 0 .713.288T13 13q0 .425-.288.713T12 14Zm4 0q-.425 0-.712-.288T15 13q0-.425.288-.712T16 12q.425 0 .713.288T17 13q0 .425-.288.713T16 14ZM5 22q-.825 0-1.412-.587T3 20V6q0-.825.588-1.412T5 4h1V2h2v2h8V2h2v2h1q.825 0 1.413.588T21 6v14q0 .825-.587 1.413T19 22H5Zm0-2h14V10H5v10Z"
        />
      </svg>
      {{ missionDateFormat }}
    </p>
    <p v-if="missionStartHour == '00:00:00' && missionEndHour == '23:59:00'">
      <svg
        class="mb-1"
        style="display: inline-block"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          fill="#806157"
          d="m14.55 16.55l1.4-1.425l-2.95-2.95V8h-2v5l3.55 3.55ZM11 6h2V4h-2v2Zm7 7h2v-2h-2v2Zm-7 7h2v-2h-2v2Zm-7-7h2v-2H4v2Zm8 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"
        />
      </svg>
      All day
    </p>
    <p v-if="missionStartHour != '00:00:00' || missionEndHour != '23:59:00'">
      <svg
        class="mb-1"
        style="display: inline-block"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          fill="#806157"
          d="m14.55 16.55l1.4-1.425l-2.95-2.95V8h-2v5l3.55 3.55ZM11 6h2V4h-2v2Zm7 7h2v-2h-2v2Zm-7 7h2v-2h-2v2Zm-7-7h2v-2H4v2Zm8 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"
        />
      </svg>
      {{ missionStartHourSplit }} to {{ missionEndHourSplit }}
    </p>
    <!-- <div
      class="text-sm text-center font-semibold w-[70%] mx-auto rounded break-words py-2 px-2 mt-1"
      style="box-shadow: rgba(0, 0, 0, .2) 0 0 5px;"
    >
    {{missionDateFormat}}
    </div> -->
    <!-- <div class="text-xl text-[#806157] font-bold mb-4">
      Mission Time: <span v-if="missionStartHour == '00:00:00' && missionEndHour == '23:59:00'" class="text-m text-[#806157] font-normal">All day</span>
      <span v-if="missionStartHour != '00:00:00' && missionEndHour != '23:59:00'" class="text-m text-[#806157] font-normal">{{ missionStartHourSplit }} to {{ missionEndHourSplit }}</span>
    </div> -->
    <!-- <div class="text-sm text-center font-semibold flex mx-auto mt-2 w-[70%]">
      <div
        class="text-sm rounded break-words py-2 px-4"
        style="box-shadow: rgba(0, 0, 0, .2) 0 0 5px;"
      >
        {{ missionStartHourSplit }}
      </div>
      <div class="mx-4 text-sm p-1 flex-1">
        to
      </div>
      <div
        class="text-sm rounded break-words py-2 px-4"
        style="box-shadow: rgba(0, 0, 0, .2) 0 0 5px;"
      >
        {{ missionEndHourSplit }}
      </div>
    </div> -->

    <!-- <div class="text-center text-xs text-[#9E9E9E] mt-6">
      All day mission?
    </div>
    <div
      v-if="missionStartHour == '00:00:00' && missionEndHour == '23:59:00'"
      class="
        bg-[#56B897]
        rounded-full
        flex
        items-center
        justify-center
        text-white
        mx-auto
        text-sm
        px-5
        mt-2
        font-semibold
      "
    >
      Yes
    </div>
    <div
      v-else
      class="
        bg-[#EC6C6B]
        rounded-full
        flex
        items-center
        justify-center
        text-white
        mx-auto
        text-sm
        px-5
        mt-2
        font-semibold
      "
    >
      No
    </div> -->

    <!-- <div
      v-if="missionStartHour == '00:00:00' && missionEndHour == '23:59:00'"
      class="text-[0.65rem] text-center mt-2 text-[#9E9E9E]"
    >
      ... you have all day to complete this mission!
    </div> -->

    <div class="rewardContainer">
      <!-- <div class="flex mx-auto justify-center items-center">
        <div class="text-center text-sm mr-5">
          Reward Type:
        </div>
        <div
          v-if="rewardType == 'money' && isRewardVisible"
          class="activity_item__amount flex items-center"
        >
          <span
            class="text-[#56B897] text-[0.70rem] mr-[2px] font-bold"
          >+</span>
          <img
            src="../assets/images/reward_money.svg"
            class="w-[16px] h-[16px] mt-[-2px]"
            alt="Money"
          >
        </div>
        <div
          v-if="rewardType == 'points' && isRewardVisible"
          class="activity_item__amount flex items-center"
        >
          <span
            class="text-[#58C1E1] text-[0.70rem] mr-[2px] font-bold"
          >+</span>
          <img
            src="../assets/images/reward_points.svg"
            class="w-[13px] h-[13px] mt-[-2px]"
            alt="Points"
          >
        </div>
        <div
          v-if="rewardType == 'wishlist' && isRewardVisible"
          class="activity_item__amount flex items-center"
        >
          <span
            class="text-[#EC6C6B] text-[0.70rem] mr-[2px] font-bold"
          >+</span>
          <img
            src="../assets/images/reward_wishlist.svg"
            class="w-[13px] h-[13px]"
            alt="Wishlist"
          >
        </div>
        <div
          v-if="!isRewardVisible"
          class="font-bold text-sm"
        >
          Hidden
        </div>
      </div> -->
      <!-- <div v-if="isRewardVisible">
        <div
          v-if="rewardType == 'wishlist'"
          class="appMissionDetails__blue"
          style="color: #fda085"
        >
          {{ message }}
        </div>
        <div v-else class="appMissionDetails__blue">
          {{ message }}
        </div>
      </div>
      <div v-else>
        <div class="appMissionDetails__blue">
          <span v-if="missionState == 'completed' && rewardType != 'wishlist'">
            {{ message }}
          </span>
          <span v-else style="color: #ff4f79">
            {{ message }}
          </span>
          <p
            v-if="!isRewardVisible && missionState != 'completed'"
            style="text-align: center; font-size: 16px; color: #ff4f79"
          >
            <small>a secret reward</small>
          </p>
        </div>
      </div> -->
      <div class="text-xl text-[#806157] font-bold mb-1">
        Reward
      </div>
      <div v-if="isRewardVisible">
        <div
          v-if="rewardType == 'money'"
          class="appMissionDetails__points flex mx-auto"
        >
          <div
            class="mt-4 h-[70px] leading-tight p-3 rounded-lg border-none bg-[#56B897] flex items-center justify-between text-white min-w-[170px]"
          >
            <div class="text-left text-xs leading-tight">
              Money
              <p class="text-xs mt-1 font-normal" style="font-size: 12px">
                +&pound;{{ formatPrice(price) }}
              </p>
            </div>
            <div
              class="bg-[#58C1E1] rounded-full h-6 w-6 flex items-center justify-center"
            >
              <img
                src="../assets/images/reward_money_modal.svg"
                class="w-3 h-3"
              />
            </div>
          </div>
        </div>
        <div
          v-else-if="rewardType == 'points'"
          class="appMissionDetails__points flex mx-auto"
        >
          <div
            class="mt-4 leading-tight p-3 rounded-lg border-none bg-[#0D4F74] flex items-center justify-between text-white min-w-[170px]"
          >
            <div class="text-left text-xs leading-tight">
              Sonik Pocket<br />
              Points
              <p class="text-xs mt-1 font-normal" style="font-size: 12px">
                +25
              </p>
            </div>
            <div
              class="bg-[#58C1E1] rounded-full h-6 w-6 flex items-center justify-center"
            >
              <img
                src="../assets/images/reward_points_modal.svg"
                class="w-3 h-3"
              />
            </div>
          </div>
        </div>
        <div
          v-else-if="rewardType == 'wishlist'"
          class="appMissionDetails__wishlist flex mx-auto"
        >
          <!-- <div
            class="
              mt-4
              h-[70px]
              leading-tight
              p-3
              rounded-lg
              border-none
              bg-[#EC6C6B]
              flex
              items-center
              justify-between
              text-white
              min-w-[170px]
            "
          >
            <div class="text-left text-xs leading-tight">
              Money
              <p class="text-xs mt-1 font-normal" style="font-size: 12px">
                +&pound;{{ formatPrice(price) }}
              </p>
            </div>
            <div
              class="
                bg-[#58C1E1]
                rounded-full
                h-6
                w-6
                flex
                items-center
                justify-center
              "
            >
              <img
                src="../assets/images/reward_wishlist_modal.svg"
                class="w-3 h-3"
              />
            </div>
          </div> -->

          <div
            class="bg-[#EC6C6B] flex items-center py-3 px-4 rounded-lg text-white h-[80px] mt-4"
          >
            <img
              :src="resolveImage(product.default_picture_link)"
              width="40"
              height="40"
              style="border-radius: 50%"
            />
            <div style="margin-left: 20px">
              <p
                class="text-sm"
                style="
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 100px;
                  height: 1.2em;
                  white-space: nowrap;
                "
              >
                {{ product.name }}
              </p>
              <p class="wishlist_price">£{{ formatPrice(product.price) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="missionState == 'completed' && isRewardVisible == false">
        <div v-if="rewardType == 'money'" class="appMissionDetails__price">
          <small>£</small>{{ formatPrice(price) }}
        </div>
        <div
          v-else-if="rewardType == 'points'"
          class="appMissionDetails__points"
        >
          25 Sonik Points
        </div>
        <div
          v-else-if="rewardType == 'wishlist'"
          class="appMissionDetails__wishlist"
        >
          <div class="wishlist_product">
            <img
              :src="resolveImage(product.default_picture_link)"
              width="70"
              height="70"
              style="border-radius: 50%"
            />
            <div style="margin-left: 20px">
              <p class="wishlist_title">
                {{ product.name }}
              </p>
              <p class="wishlist_price">
                Price: £{{ formatPrice(product.price) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="mt-6"
        style="
          text-align: center;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
        "
      >
        <p class="text-[0.65rem] mb-4">
          complete your mission to discover<br />
          what your reward will be!
        </p>
        <img
          src="../assets/images/reward_hidden.svg"
          width="100"
          height="100"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import countdown from "@chenfengyuan/vue-countdown";
import config from "../config";
import moment from "moment";
export default {
  // components: {
  //   countdown,
  // },
  props: {
    missionId: {
      type: Number,
      default: 0,
    },
    product: {
      type: Object,
    },
    missionDate: {
      type: String,
    },
    missionStartHour: {
      type: String,
    },
    missionEndHour: {
      type: String,
    },
    isRewardVisible: {
      type: Boolean,
    },
    timeleft: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: "",
    },
    missionState: {
      type: String,
      default: "",
    },
    rewardType: {
      type: String,
      default: "",
    },
    price: {
      // type: Number,
      // default: 0
    },
    statusText: {
      type: String,
      default: "",
    },
    headingColor: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
  },
  computed: {
    missionDateFormat() {
      const dateArray = this.missionDate.split("-");
      const day = dateArray[0];
      const month = dateArray[1];
      const year = dateArray[2];
      let date = new Date(`${year}-${month}-${day}`);

      return moment(date.toISOString()).format("D MMMM YYYY");
    },
    message() {
      if (this.status == "failed") return "You would have received";
      if (this.status === "in_progress") {
        return "You will receive";
      } else if (this.status === "pending_reward") return "You will receive";
      else if (this.status === "completed") return "You received";
      else return "You would have received";
    },
    missionStartHourSplit() {
      var hour = this.missionStartHour.split(":");
      return `${hour[0]}:${hour[1]}`;
    },
    missionEndHourSplit() {
      var hour = this.missionEndHour.split(":");
      return `${hour[0]}:${hour[1]}`;
    },
  },
  methods: {
    resolveImage(src) {
      return config.HOST + src;
    },
    monthToName(str) {
      var monthShortNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var date = str.split("-").reverse().join("-");
      var parts = date.split("-");
      var getMonth = parts[1] - 1 < 10 ? "0" + parts[1] : parts[1];
      var getDay = parts[2] < 10 ? "0" + parts[2] : parts[2];
      var t = new Date(parts[0], getMonth, getDay);
      return t.getDate() + " " + monthShortNames[t.getMonth() - 1];
    },
  },
};
</script>

<style lang="scss">
.appMissionDetails {
  position: relative;
  height: auto;
  background: #ffffff;
  border-radius: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;

  &__heading {
    padding-top: 0px;

    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #7a615a;
  }

  &__title {
    text-align: center;
    font-weight: bold;
    color: #7a615a;
  }

  &__description {
    font-weight: 400;
    text-align: center;
    font-size: 16px;
  }

  &__blue {
    margin: 0 20px;
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #36d5f2;
  }
  &__price {
    text-align: center;
    font-weight: 700;
    margin: 40px 0px;
    font-size: 25px;
    color: var(--main);
  }
  &__points {
    text-align: center;
    font-weight: 700;
    font-size: 25px;
    color: var(--main);
  }
}
.modalTimer {
  background: #fff8ce;
  background: linear-gradient(180deg, #fff7e0 0%, rgba(255, 212, 79, 0) 100%),
    #fff8ce;
  border-radius: 20px;
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #7a615a;
  opacity: 0.75;
  padding: 8px 25px;
}

.rewardContainer {
  margin: 60px 0;
}

.wishlist_product {
  border: 1px dashed #e5e5e5;
  background: white;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  height: 100px;
  width: 100%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  font-size: 20px;
  margin-bottom: 10px;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0 20px;
  text-align: left;
  color: #7a615a;
  width: 50%;
  margin: 0 auto;
  margin-top: 20px;
  background: linear-gradient(360deg, #c23616 35.62%, #ff4f79 103.95%);
  border: 1px solid #edb634;
  box-sizing: border-box;
  color: white;
  box-shadow: 0px 4px 20px #c2361605;
  background: linear-gradient(
    360deg,
    #f6d365 7.59%,
    #f6d365 11.16%,
    #f6d166 14.73%,
    #f7cf68 18.3%,
    #f7cb6a 21.87%,
    #f8c76c 25.45%,
    #f8c270 29.02%,
    #f9bc73 32.59%,
    #fab777 36.16%,
    #fbb17a 39.73%,
    #fbac7e 43.3%,
    #fca880 46.87%,
    #fca482 50.45%,
    #fda284 54.02%,
    #fda085 57.59%,
    #fda085 61.16%
  );
  img {
    object-fit: cover;
    box-shadow: 0 0 0 4px #fda085, 0 0 5px #fff;
  }
}

.wishlist_product::after {
  content: "\2714";
  position: absolute;
  color: white;
  top: -10px;
  right: -10px;
  width: 40px;
  height: 40px;
  background: #b8e994;
  background: linear-gradient(180deg, #7fe049 35.22%, #47ffc7 104.78%);
  border-radius: 50%;
  border: 2px solid #7fe049;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.08),
    0px 0px 1px rgba(0, 0, 0, 0.08);
}

.wishlist_title {
  margin-top: 5px;
  font-size: 16px;
}
.wishlist_price {
  margin-top: 5px;
  font-size: 12px;
}
</style>
