<template>
    <div>
        <header>
            <div
                v-if="weekMissions"
                style="
                    text-align: center;
                    color: var(--main);
                    line-height: 28px;
                    padding: 40px 40px 20px 40px;
                "
            >
                <h1 style="font-size: 25px; font-weight: 700">Missions</h1>
                <p style="font-size: 12px; font-weight: 600">
                    Complete them to receive rewards
                </p>
            </div>
            <div
                v-else
                style="
                    text-align: center;
                    color: var(--main);
                    line-height: 28px;
                    padding: 40px 40px 20px 40px;
                "
            >
                <h1 style="font-size: 25px; font-weight: 700">History</h1>
                <p style="font-size: 12px; font-weight: 600">
                    Your missions history
                </p>
            </div>

            <div
                @click="weekMissions = !weekMissions"
                class="flex justify-end text-xs font-semibold"
            >
                <span
                    v-if="weekMissions"
                    class="text-white px-3 py-1"
                    style="
                        background: var(--main);
                        border-radius: 50px 0 0 50px;
                    "
                    >Missions History</span
                >
                <span
                    v-else
                    class="text-white px-3 py-1"
                    style="
                        background: var(--main);
                        border-radius: 50px 0 0 50px;
                    "
                    >Weekly Missions</span
                >
            </div>

            <div
                v-if="weekMissions"
                class="missionDays"
                style="padding: 0 0 20px 10px; display: none"
            >
                <div
                    class="missionDays__item"
                    :class="{ missionDays__item__active: activeIndex === -1 }"
                    @click="changeMissionDay(-1, null, false)"
                >
                    <div>
                        <span class="missionDays__item-username">Today</span>
                    </div>
                </div>

                <div
                    v-for="(day, index) in nextSixDays"
                    :key="index"
                    class="missionDays__item"
                    :class="{
                        missionDays__item__active: activeIndex === index,
                    }"
                    @click="changeMissionDay(index, day.day, true)"
                >
                    <div>
                        <span
                            v-if="day.day == nextSixDays[0].day"
                            class="missionDays__item-username"
                            >Tomorrow</span
                        >
                        <span v-else class="missionDays__item-username">{{
                            day.formatted
                        }}</span>
                    </div>
                </div>
            </div>
        </header>

        <div
            v-if="weekMissions"
            class="missionDays"
            style="padding: 20px 0 28px 10px; margin-bottom: 40px"
        >
            <div
                class="missionDays__item relative"
                :class="{ missionDays__item__active: activeIndex === -1 }"
                @click="changeMissionDay(-1, null, false)"
            >
                <div>
                    <span class="missionDays__item-username">Today</span>
                </div>
                <span
                    class="
                        absolute
                        bottom-[-25px]
                        text-sm
                        left-0
                        right-0
                        mx-auto
                        flex
                        items-center
                        justify-center
                        text-[#01a4c0]
                    "
                    :class="{ activeDayColor: activeIndex === -1 }"
                    >{{ monthToName(currentDayMethod) }}</span
                >
            </div>

            <div
                v-for="(day, index) in nextSixDays"
                :key="index"
                class="missionDays__item relative"
                :class="{ missionDays__item__active: activeIndex === index }"
                @click="changeMissionDay(index, day.day, true)"
            >
                <div>
                    <span
                        v-if="day.day == nextSixDays[0].day"
                        class="missionDays__item-username"
                        >Tomorrow</span
                    >
                    <span v-else class="missionDays__item-username">{{
                        day.formatted
                    }}</span>
                </div>
                <span
                    class="
                        absolute
                        bottom-[-25px]
                        text-sm
                        left-0
                        right-0
                        mx-auto
                        flex
                        items-center
                        justify-center
                        text-[#01a4c0]
                    "
                    :class="{ activeDayColor: activeIndex === index }"
                    >{{ monthToName(day.day) }}</span
                >
            </div>
        </div>
        <section v-if="weekMissions" id="missions" style="padding: 0 20px;">
        <div
        v-if="
          getFilteredMiniMissionsGetter.pending_reward.length > 0 ||
          getFilteredMiniMissionsGetter.in_progress.length > 0 ||
          getFilteredMiniMissionsGetter.completed.length > 0 ||
          getFilteredMiniMissionsGetter.failed.length > 0 ||
          getFilteredMiniMissionsGetter.expired.length > 0
        "
      >

      <AppMission
            v-for="(mission, index) in getFilteredMiniMissionsGetter.in_progress"
            :key="index"
            :title="mission.name"
            :mission-id="mission.missionDateTimeId"
            :description="mission.description"
            :price="mission.sum_money.toString()"
            :status-text="getStatusText(mission)"
            :active-sum-points="mission.sum_points.toString()"
            :type="mission.reward_type"
            :reward-visible="mission.is_reward_visible"
            :wishlist-product="mission.product"
            :timeleft="Number(mission.milisecondsDateTime)"
            :start-date="mission.date_times_mission.start_date"
            :status="mission.date_times_mission.status"
            @details="openSheetMissionDetails(mission)"
        />

        <div style="background: #EBEBEB; height: 2px; width: 95%; display: block;margin: 20px auto 20px auto;"></div>
     
        <AppMission
            v-for="(mission, index) in getFilteredMiniMissionsGetter.pending_reward"
            :key="index"
            :title="mission.name"
            :mission-id="mission.missionDateTimeId"
            :description="mission.description"
            :price="mission.sum_money.toString()"
            :status-text="getStatusText(mission)"
            :active-sum-points="mission.sum_points.toString()"
            :type="mission.reward_type"
            :reward-visible="mission.is_reward_visible"
            :wishlist-product="mission.product"
            :timeleft="Number(mission.milisecondsDateTime)"
            :start-date="mission.date_times_mission.start_date"
            :status="mission.date_times_mission.status"
            @details="openSheetMissionDetails(mission)"
        />

        <AppMission
            v-for="(mission, index) in getFilteredMiniMissionsGetter.completed"
            :key="index"
            :title="mission.name"
            :mission-id="mission.missionDateTimeId"
            :description="mission.description"
            :price="mission.sum_money.toString()"
            :status-text="getStatusText(mission)"
            :active-sum-points="mission.sum_points.toString()"
            :type="mission.reward_type"
            :reward-visible="mission.is_reward_visible"
            :wishlist-product="mission.product"
            :timeleft="Number(mission.milisecondsDateTime)"
            :start-date="mission.date_times_mission.start_date"
            :status="mission.date_times_mission.status"
            @details="openSheetMissionDetails(mission)"
        />

        <AppMission
            v-for="(mission, index) in getFilteredMiniMissionsGetter.failed"
            :key="index"
            :title="mission.name"
            :mission-id="mission.missionDateTimeId"
            :description="mission.description"
            :price="mission.sum_money.toString()"
            :status-text="getStatusText(mission)"
            :active-sum-points="mission.sum_points.toString()"
            :type="mission.reward_type"
            :reward-visible="mission.is_reward_visible"
            :wishlist-product="mission.product"
            :timeleft="Number(mission.milisecondsDateTime)"
            :start-date="mission.date_times_mission.start_date"
            :status="mission.date_times_mission.status"
            @details="openSheetMissionDetails(mission)"
        />

        <AppMission
            v-for="(mission, index) in getFilteredMiniMissionsGetter.expired"
            :key="index"
            :title="mission.name"
            :mission-id="mission.missionDateTimeId"
            :description="mission.description"
            :price="mission.sum_money.toString()"
            :status-text="getStatusText(mission)"
            :active-sum-points="mission.sum_points.toString()"
            :type="mission.reward_type"
            :reward-visible="mission.is_reward_visible"
            :wishlist-product="mission.product"
            :timeleft="Number(mission.milisecondsDateTime)"
            :start-date="mission.date_times_mission.start_date"
            :status="mission.date_times_mission.status"
            @details="openSheetMissionDetails(mission)"
        />
        
      </div>
      <div v-else class="recent_container">
                <div
                    class="activity_item shadow"
                    style="justify-content: center; align-items: center"
                >
                    <div
                        style="
                            text-align: center;
                            color: var(--secondary);
                            font-size: 12px;
                            font-weight: 500;
                        "
                    >
                        You have no missions for this day,<br />
                        check back soon!
                    </div>
                </div>
            </div>
        </section>

        <section v-if="weekMissions" id="missions" style="padding: 0 20px; display: none;">
            <div v-if="takeMiniMissions.length > 0">
                <div
                    v-if="
                        takeMiniMissions.filter(
                            (e) => e.date_times_mission.start_date == activeDay
                        ).length > 0
                    "
                >
                    <AppMission
                        v-for="(mission, index) in takeMiniMissions.filter(
                            (e) => e.date_times_mission.start_date == activeDay
                        )"
                        :key="index"
                        :title="mission.name"
                        :mission-id="mission.missionDateTimeId"
                        :description="mission.description"
                        :price="mission.sum_money.toString()"
                        :status-text="getStatusText(mission)"
                        :active-sum-points="mission.sum_points.toString()"
                        :type="mission.reward_type"
                        :reward-visible="mission.is_reward_visible"
                        :wishlist-product="mission.product"
                        :timeleft="Number(mission.milisecondsDateTime)"
                        :start-date="mission.date_times_mission.start_date"
                        :status="mission.date_times_mission.status"
                        @details="openSheetMissionDetails(mission)"
                    />
                </div>
                <div v-else class="recent_container">
                    <div
                        class="activity_item shadow"
                        style="justify-content: center; align-items: center"
                    >
                        <div
                            style="
                                text-align: center;
                                color: var(--secondary);
                                font-size: 12px;
                                font-weight: 500;
                            "
                        >
                            You have no missions today,<br />
                            check back soon!
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="recent_container">
                <div
                    class="activity_item shadow"
                    style="justify-content: center; align-items: center"
                >
                    <div
                        style="
                            text-align: center;
                            color: var(--secondary);
                            font-size: 12px;
                            font-weight: 500;
                        "
                    >
                        You have no missions today,<br />
                        check back soon!
                    </div>
                </div>
            </div>
        </section>

        <section v-if="!weekMissions" id="missions" class="mt-5" style="padding: 0 20px;">
            <div v-if="takeMiniMissionsHistory.length > 0">
                <AppMission
                    v-for="(mission, index) in takeMiniMissionsHistory"
                    :key="index"
                    :title="mission.name"
                    :mission-id="mission.missionDateTimeId"
                    :description="mission.description"
                    :price="mission.sum_money.toString()"
                    :status-text="getStatusText(mission)"
                    :active-sum-points="mission.sum_points.toString()"
                    :type="mission.reward_type"
                    :reward-visible="mission.is_reward_visible"
                    :wishlist-product="mission.product"
                    :timeleft="Number(mission.milisecondsDateTime)"
                    :start-date="mission.date_times_mission[0].start_date"
                    :status="mission.date_times_mission[0].status"
                    @details="openSheetMissionHistoryDetails(mission)"
                />
            </div>
            <div v-else class="recent_container">
                <div
                    class="activity_item shadow"
                    style="justify-content: center; align-items: center"
                >
                    <div
                        style="
                            text-align: center;
                            color: var(--secondary);
                            font-size: 12px;
                            font-weight: 500;
                        "
                    >
                        You have no missions history!
                    </div>
                </div>
            </div>
        </section>

        <Sheet
            :show.sync="sheetMissionDetails"
            :on-close="closeSheetMissionDetails"
        >
            <img
                :src="resolveImage(user.avatarLink)"
                class="rounded-full object-cover w-[80px] h-[80px] mx-auto"
                alt="Avatar"
            />
            <div class="text-center font-bold mb-6">
                {{ user.first_name }}
            </div>
            <AppMissionDetails
                v-if="Object.keys(missionObject).length > 0"
                :title="missionObject.name"
                :mission-date="missionObject.date_times_mission.start_date"
                :mission-start-hour="
                    missionObject.date_times_mission.start_time
                "
                :mission-end-hour="missionObject.date_times_mission.end_time"
                :description="missionObject.description"
                :price="missionObject.sum_money.toString()"
                :reward-type="missionObject.reward_type"
                :is-reward-visible="missionObject.is_reward_visible"
                :product="missionObject.product"
                :status="missionObject.date_times_mission.status"
            />
            <!-- <div v-if="Object.keys(missionObject).length > 0" class="flex">
        <button
          class="text-normal bg-black text-white max-w-[200px] py-3 mx-auto"
          v-if="missionObject.date_times_mission.status == 'in_progress'"
          @click="setMssionStatusAsDone(missionObject.missionDateTimeId)"
        >
          I did it
        </button>
      </div> -->

            <div
                v-if="Object.keys(missionObject).length > 0"
                class="flex justify-center"
            >
                <button
                    v-if="
                        missionObject.date_times_mission.status == 'in_progress'
                    "
                    class="
                        rounded-[100px]
                        py-2
                        px-8
                        text-white text-xs
                        font-bold
                    "
                    @click="
                        setMssionStatusAsDone(missionObject.missionDateTimeId)
                    "
                    :disabled="activeDay != currentDayMethod"
                    :class="{
                        'bg-[#58C1E1]': activeDay == currentDayMethod,
                        'bg-[#C4C4C4]': activeDay != currentDayMethod,
                    }"
                >
                    I did the mission
                </button>
            </div>
        </Sheet>

        <Sheet
            :show.sync="sheetMissionHistoryDetails"
            :on-close="closeSheetMissionHistoryDetails"
        >
            <img
                :src="resolveImage(user.avatarLink)"
                class="rounded-full object-cover w-[80px] h-[80px] mx-auto"
                alt="Avatar"
            />
            <div class="text-center font-bold mb-6">
                {{ user.first_name }}
            </div>
            <AppMissionDetails
                v-if="Object.keys(missionObjectHistory).length > 0"
                :title="missionObjectHistory.name"
                :mission-date="missionObjectHistory.date_times_mission[0].start_date"
                :mission-start-hour="
                    missionObjectHistory.date_times_mission[0].start_time
                "
                :mission-end-hour="missionObjectHistory.date_times_mission[0].end_time"
                :description="missionObjectHistory.description"
                :price="missionObjectHistory.sum_money.toString()"
                :reward-type="missionObjectHistory.reward_type"
                :is-reward-visible="missionObjectHistory.is_reward_visible"
                :product="missionObjectHistory.product"
                :status="missionObjectHistory.date_times_mission[0].status"
            />

        </Sheet>
    </div>
</template>

<script>
    import AppMission from "../../components/AppMission";
    import AppMissionDetails from "../../components/AppMissionDetails";
    import Sheet from "../../components/Sheet";
    import { mapGetters, mapActions } from "vuex";
    import config from "../../config";

    export default {
        components: {
            AppMission,
            AppMissionDetails,
            Sheet,
        },
        data() {
            return {
                activeDay: null,
                activeIndex: -1,
                mini_missions: [],
                skill_missions: [],
                nextSixDays: [],
                finalDays: [],
                missionObject: [],
                missionObjectHistory: [],
                sheetMissionDetails: false,
                sheetMissionHistoryDetails: false,
                weekMissions: true,
            };
        },
        methods: {
            ...mapActions([
                "fetchMissions",
                "setupUser",
                "fetchProfile",
                "editMssionStatus",
                "setMissionDayParam"
            ]),
            monthToName(str) {
                var monthShortNames = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ];
                var date = str.split("-").reverse().join("-");
                var parts = date.split("-");
                var getMonth = parts[1];
                var getDay = parts[2];
                var t = new Date(`${parts[0]}/${getMonth}/${getDay}`);
                return t.getDate() + " " + monthShortNames[t.getMonth()];
            },
            getStatusText(mission) {
                if (mission.conditions == null) return null;
                if (mission.conditions.length == 0) return null;
                return mission.conditions.time;
            },
            setMssionStatusAsDone(id) {
                const payload = {
                    date: this.activeDay,
                    missionId: id
                };
                this.editMssionStatus(payload);
                this.sheetMissionDetails = false;
            },
            openSheetMissionDetails(item) {
                this.missionObject = item;
                this.sheetMissionDetails = true;
            },
            closeSheetMissionDetails() {
                this.sheetMissionDetails = false;
            },
            openSheetMissionHistoryDetails(item) {
                this.missionObjectHistory = item;
                this.sheetMissionHistoryDetails = true;
            },
            closeSheetMissionHistoryDetails() {
                this.sheetMissionHistoryDetails = false;
            },
            changeMissionDay(index, day, type) {
                this.activeIndex = index;
                this.activeDay = type ? day : this.currentDayMethod;
                this.fetchMissions(type ? day : this.currentDayMethod);
                this.setMissionDayParam(type ? day : this.currentDayMethod);
            },
            GetDates(startDate, daysToAdd) {
                var aryDates = [];

                for (var i = 1; i <= daysToAdd; i++) {
                    var currentDate = new Date();
                    currentDate.setDate(startDate.getDate() + i);
                    var day = currentDate.getDate();
                    var month = currentDate.getMonth() + 1;
                    // var day = this.str_pad(currentDate.getDate());
                    // var month = this.str_pad(currentDate.getMonth() + 1);
                    const days = {
                        day: day + "-" + month + "-" + currentDate.getFullYear(),
                        formatted: this.DayAsString(currentDate.getDay()),
                    };
                    // const days = {
                    //   day: day + "-" + month + "-" + currentDate.getFullYear(),
                    //   formatted:
                    //     this.DayAsString(currentDate.getDay()) +
                    //     ", " +
                    //     currentDate.getDate() +
                    //     " " +
                    //     this.MonthAsString(currentDate.getMonth() + 1) +
                    //     " " +
                    //     currentDate.getFullYear(),
                    // };
                    // console.log(day);
                    // console.log(month);
                    // aryDates.push(currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear());
                    // aryDates.push(day + "-" + month + "-" + currentDate.getFullYear());
                    aryDates.push(days);
                }
                // console.log(currentDate.getMonth() < 10 ? '0' + currentDate.getMonth() : currentDate.getMonth())
                // console.log(aryDates);
                return aryDates;
            },
            MonthAsString(monthIndex) {
                // var d = new Date();
                var month = new Array();
                month[1] = "January";
                month[2] = "February";
                month[3] = "March";
                month[4] = "April";
                month[5] = "May";
                month[6] = "June";
                month[7] = "July";
                month[8] = "August";
                month[9] = "September";
                month[10] = "October";
                month[11] = "November";
                month[12] = "December";

                return month[monthIndex];
            },

            DayAsString(dayIndex) {
                const weekdays = new Array(7);
                weekdays[0] = "Sunday";
                weekdays[1] = "Monday";
                weekdays[2] = "Tuesday";
                weekdays[3] = "Wednesday";
                weekdays[4] = "Thursday";
                weekdays[5] = "Friday";
                weekdays[6] = "Saturday";

                return weekdays[dayIndex];
            },
            str_pad(n) {
                return String("0" + n).slice(-2);
            },
            nextDays() {
                if (Object.keys(this.takeMiniMissions).length > 0) {
                    this.nextSixDays.forEach((day) => {
                        // console.log(day)
                        this.takeMiniMissions.forEach((item) => {
                            // console.log(item);
                            if (day.day == item.date_times_mission.start_date) {
                                // console.log(item);

                                const datesAndMissions = {
                                    mission: item,
                                    day: day,
                                };

                                // console.log(datesAndMissions);
                                this.finalDays.push(datesAndMissions);
                            }
                        });
                    });
                }
            },
            haveTask(day) {
                return this.takeMiniMissions.some(
                    (mission) => mission.date_times_mission.start_date === day
                );
            },
            resolveImage(src) {
                return config.HOST + src;
            },
        },
        computed: {
            ...mapGetters([
                "takeMiniMissions",
                "takeMiniMissionsHistory",
                "takeMegaMission",
                "user",
                "getFilteredMiniMissionsGetter"
            ]),
            currentDayMethod() {
                var d = new Date(),
                    month = "" + (d.getMonth() + 1),
                    day = "" + d.getDate(),
                    year = d.getFullYear();
                // console.log(new Date());
                return [day, month, year].join("-");
            },
            todayDate() {
                return new Date("5-4-2021")
                    .toISOString()
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("-");
            },
        },
        created() {
            var startDate = new Date();
            this.nextSixDays = this.GetDates(startDate, 6);

            const user = {
                userId: localStorage.getItem("children_user_id"),
                token: localStorage.getItem("children_token"),
                family_id_check: localStorage.getItem("children_family_id"),
            };
            this.fetchProfile();
            this.setupUser(user);

            this.changeMissionDay(-1, null, false);
            this.fetchMissions(this.activeDay);
            this.setMissionDayParam(this.activeDay);
        },
        mounted() {
            this.nextDays();
            this.mini_missions = this.$store.state.miniMissions;

            console.log(this.takeMiniMissions);
        },
    };
</script>

<style lang="scss" scoped>
    .missionDays {
        display: flex;
        align-items: center;
        flex: 1;
        overflow: auto hidden;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 99;
        background: transparent;
    }

    .missionDays__item {
        user-select: none;
        margin-right: 10px;
        border: 1px solid var(--main);
        border-radius: 100px;

        &__active {
            background: var(--ternary);
            border-radius: 100px;
            border: 1px solid var(--ternary);
            color: white !important;

            .missionDays__item-username {
                color: white;
            }
        }
    }

    .missionDays__item-username {
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 600;
        color: var(--main);
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
    }

    #missions {
        h1 {
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.02rem;
            text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        }

        .recent_container {
            margin-top: 20px;
        }

        .activity_item {
            width: 100%;
            height: 90px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            background: white;
            padding: 15px;
            border-radius: 15px;
            position: relative;
            overflow: hidden;
            transition: all 200ms ease-in-out !important;
            box-shadow: rgba(0, 0, 0, 0.05) 0 0 10px;

            &:active {
                opacity: 0.6;
            }

            &__container {
                display: flex;
                align-items: center;
            }

            &__title {
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 0.01rem;
                // text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 135px;
            }
        }
    }
    /********************************************************************** OLD CSS */

    .missions {
        // display: grid;
        // grid-template-columns: 10fr 3fr;
        height: 100%;

        &__center {
            height: 100%;
            overflow-y: scroll;
            padding: 0 10px;

            &__myMissions {
                &__title {
                    padding: 30px;

                    font-size: 35px;
                    line-height: 24px;
                    color: #7a615a;
                }
            }

            &__missions {
                &__mini {
                    background: #f8f5ed;
                    border-radius: 40px;
                    height: auto;

                    &__title {
                        padding: 15px 0 25px 0;
                        text-align: center;

                        font-size: 20px;
                        line-height: 24px;
                        color: #7a615a;
                    }
                    &__container {
                        display: grid;
                        grid-gap: 25px;
                        padding: 16px;
                        margin: 20px;
                        grid-template-columns: repeat(
                            auto-fill,
                            minmax(250px, 1fr)
                        );
                        grid-auto-flow: column;
                        grid-auto-columns: minmax(250px, 1fr);
                        overflow-x: auto;
                        overflow-y: hidden;

                        &__info {
                            display: grid;
                            grid-template-rows: 10fr 3fr;
                            grid-gap: 20px;
                        }
                    }
                }

                &__skill {
                    background: #f8f5ed;
                    border-radius: 40px;
                    height: auto;
                    margin-top: 30px;
                    &__title {
                        padding: 30px;

                        font-size: 20px;
                        line-height: 24px;
                        color: #7a615a;
                    }

                    &__container {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-gap: 25px;
                        margin: 0 20px;
                        height: auto;

                        &__info {
                            display: grid;
                            grid-template-rows: 10fr 3fr;
                            grid-gap: 20px;
                        }
                    }
                }
            }
        }

        &__sidebar {
            height: 100%;
            // margin-left: 10px;
        }
    }

    @media screen and (max-width: 800px) {
        .missions {
            display: block;
            grid-template-columns: 1fr;

            // &__sidebar {
            //     // display: none;
            // }
            &__center {
                height: auto;
                overflow-y: hidden;
                padding: 0;
            }
        }
        .missions__bottom {
            grid-template-columns: 1fr;
        }
        .missions__center__missions__container {
            grid-auto-flow: row;
        }
        .missions__sidebar {
            margin-top: 15px;
        }
        .missions__center__missions__mini__container {
            display: block;
            grid-template-columns: 1fr;
            padding: 0;
        }
    }

    .no-mega-mission {
        display: none;
    }

    .missions-subtitle {
        display: none;
    }

    @media only screen and (max-width: 820px) {
        .missions__sidebar {
            margin: 0 15px 25px 15px;
        }
        .missions-subtitle {
            display: block;
            font-size: 12px;
        }

        .missions__center__missions__skill {
            display: none;
        }
        // .missions__center__myMissions__title {
        //   display: none;
        // }
        .appMegaMissionItem__progress {
            width: 100% !important;
        }
        .missions__center__missions__mini {
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            background: transparent;
        }
        .missions__center__myMissions__title {
            text-align: center;
            font-size: 20px;
        }
        .sidebarNextMissions {
            margin-top: 0 !important;
        }
        .missions_container {
            height: 100% !important;
            overflow: hidden !important;
        }
        .missions__center__missions__mini {
            margin: 0;
            border-radius: 20px;
            overflow: hidden;
        }
        .no-mega-mission {
            background-image: url("../../assets/images/main_mission_background.jpg");
            background-size: cover;
            background-position: center;
            border-radius: 20px;
            padding: 60px 30px;
            color: white;
            text-align: center;
        }
        .no-mega-mission {
            display: block;
        }
        .desktop-mega-mission {
            display: none;
        }
    }

    .missions__center__missions__mini {
        padding: 17px;
        padding-bottom: 0;
        overflow: hidden;
    }
    .mission-col {
        margin-bottom: 35px;
    }
    .missions_container {
        height: 440px;
        overflow: auto;
        padding-top: 30px;
    }
    .missions_container::-webkit-scrollbar {
        display: none;
    }

    .tabs-component {
        margin: 2em 0;
        z-index: -1;
    }

    .tabs-component-tabs {
        //   border: solid 1px #7a615a;
        border-radius: 6px;
        position: relative;
        bottom: -10px;
        //   margin-bottom: 5px;
    }

    @media (min-width: 700px) {
        .tabs-component-tabs {
            border: 0;
            align-items: stretch;
            display: flex;
            justify-content: space-between;
            // margin-bottom: -1px;
        }
    }

    .tabs-component-tab {
        color: #7a615a;
        //   color: white;
        font-size: 16px;
        font-weight: 200;
        margin-right: 0;
        list-style: none;

        width: 100%;
        text-align: center;
    }

    // .tabs-component-tab:not(:last-child) {
    //   border-bottom: solid 1px #7a615a;
    // }

    // .tabs-component-tab:hover {
    //   color: #666;
    // }

    .tabs-component-tab.is-active {
        color: #000;
    }

    .tabs-component-tab.is-disabled * {
        color: #cdcdcd;
        cursor: not-allowed !important;
    }

    @media (min-width: 700px) {
        .tabs-component-tab {
            background-color: #f8f5ed;
            // background-color: #7a615a;
            // border: solid 2px #7a615a;
            border-radius: 10px;
            margin-right: 0.5em;
            transform: translateY(0);
            transition: transform 0.3s ease;
            // padding: 15px;
            text-align: center;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 0;
            // padding-bottom: 25px;
            border: solid 1px #ddd;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .tabs-component-tab.is-active {
            // border-bottom: solid 1px #fff;
            // background: #36d5f2;
            z-index: 2;
            color: white;
            // border-color: #36d5f2;
            // background: linear-gradient(181.4deg, #2ccbe8 35.62%, #6cf9ff 103.95%);
            // border: 2px solid #36d5f2;
            // box-shadow: 0 0 10px #36d5f2;
            transform: translateY(-8px);
            z-index: 0;
        }

        .tabs-component-tab.is-active:first-child {
            background: linear-gradient(181.4deg, #2ccbe8 35.62%, #6cf9ff 103.95%);
            box-shadow: 0 0 10px #36d5f2;
            border: none;
        }
        .tabs-component-tab.is-active:nth-child(2) {
            background: linear-gradient(
                180deg,
                rgba(19, 222, 10, 1) 0%,
                rgba(133, 242, 0, 1) 100%
            );
            box-shadow: 0 0 10px rgba(19, 222, 10, 1);
            border: none;
        }
        .tabs-component-tab.is-active:nth-child(3) {
            background: linear-gradient(
                180deg,
                rgba(222, 90, 10, 1) 0%,
                rgba(252, 115, 57, 1) 100%
            );
            box-shadow: 0 0 10px rgba(222, 90, 10, 1);
            border: none;
        }
        .tabs-component-tab.is-active:nth-child(4) {
            background: linear-gradient(
                180deg,
                rgba(97, 77, 71, 1) 0%,
                rgba(122, 97, 90, 1) 100%
            );
            box-shadow: 0 0 10px rgba(97, 77, 71, 1);
            border: none;
        }
        .tabs-component-tab.is-active:nth-child(5) {
            background: linear-gradient(
                180deg,
                rgba(222, 179, 10, 1) 0%,
                rgba(242, 241, 0, 1) 100%
            );
            box-shadow: 0 0 10px rgba(222, 179, 10, 1);
            border: none;
        }

        .tabs-component-tab.is-active > a {
            margin-top: 0;
        }

        //   .tabs-component-tab:first-child {
        //     background: linear-gradient(181.4deg, #2ccbe8 35.62%, #6cf9ff 103.95%);
        //     border: 3px solid #36d5f2;
        //     color: white;
        //   }
        .tabs-component-tab:first-child {
            border-bottom-left-radius: 0;
        }
        .tabs-component-tab:last-child {
            border-bottom-right-radius: 0;
            margin-right: 0;
        }
    }

    .tabs-component-tab-a {
        align-items: center;
        color: inherit;
        display: flex;
        //   padding: 0.75em 1em;
        text-decoration: none;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-top: -8px;
    }

    // .tabs-component-panels {
    //   padding: 4em 0;
    // }

    .tabs-component-panels::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: 700px) {
        .tabs-component-panels {
            border-top-left-radius: 0;
            background-color: #f8f5ed;
            border: solid 1px #ddd;
            border-radius: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            // padding: 4em 2em;
            // border: solid 2px #7a615a;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            // margin-top: 10px;
            padding: 20px 20px 20px 20px;
            box-sizing: border-box;
            position: relative;
            z-index: 1;
            height: 420px;
            overflow: auto;
        }
    }

    @media (min-width: 700px) {
        .suffix {
            position: absolute;
            right: -5px;
            top: -0.725em;
            width: 17px;
            height: 17px;
            border-radius: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            padding-bottom: 1px;
        }
    }
    .suffix {
        background-color: #7a615a;
        color: #fff;
        margin-left: 0.35em;
    }
    // .appContent {
    //   background: #f8f5ed;
    // }

    .activeDayColor {
        color: #303f65 !important;
    }
</style>
