<template>
  <div
    class="activity_item"
    :class="cardStatusColor"
    @click="$emit('details')"
  >
    <div class="activity_item__container">
      <!-- <div class="activity_item__icon">
        <img
          v-if="status == 'in_progress'"
          src="../assets/images/mission-in-progress.svg"
          alt="in progress"
        >
        <img
          v-else-if="status == 'pending_reward'"
          src="../assets/images/mission-pending.svg"
          alt="pending reward"
        >
        <img
          v-else-if="status == 'completed'"
          src="../assets/images/mission-complete.svg"
          alt="completed"
        >
        <img
          v-else-if="status == 'failed' || status == 'expired'"
          src="../assets/images/mission-failed.svg"
          alt="failed"
        >
      </div> -->
      <div>
        <div class="activity_item__title">
          {{ title }}
        </div>
        <div class="activity_item__description">
          {{ description }}
        </div>
        <div
          class="activity_item__name"
        >
          {{ childName }}
        </div>
      </div>
    </div>

    <div
      v-if="type == 'money'"
      class="activity_item__amount flex items-center"
    >
      <span class="text-[#56B897] text-[0.70rem] mr-[2px] font-bold">+</span>
      <img
        src="../assets/images/reward_money.svg"
        class="w-[20px] h-[20px] mt-[-2px]"
        alt="Money"
      >
    </div>
    <div
      v-if="type == 'points'"
      class="activity_item__amount flex items-center"
    >
      <span class="text-[#58C1E1] text-[0.70rem] mr-[2px] font-bold">+</span>
      <img
        src="../assets/images/reward_points.svg"
        class="w-[20px] h-[20px] mt-[-2px]"
        alt="Points"
      >
    </div>
    <div
      v-if="type == 'wishlist'"
      class="activity_item__amount flex items-center"
    >
      <span class="text-[#EC6C6B] text-[0.70rem] mr-[2px] font-bold">+</span>
      <img
        src="../assets/images/reward_wishlist.svg"
        class="w-[20px] h-[20px]"
        alt="Wishlist"
      >
    </div>

    <!-- <div v-if="type == 'money'" class="activity_item__amount">
      + &pound;{{ formatPrice(price.toString()) }}
    </div>
    <div v-if="type == 'points'" class="activity_item__amount">
      + {{ activeSumPoints.toString() }} Points
    </div>
    <div v-if="type == 'wishlist'" class="activity_item__amount">
      Product Reward
    </div> -->
    <!-- <div
      v-if="stages.length > 1"
      style="
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 15px;
        height: 15px;
      "
      class="bullet"
    ></div> -->
    <div
      style="
        display: flex;
        position: absolute;
        bottom: 0;
        right: 0;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
      "
    >
      <span
        v-if="status == 'completed'"
        class="
          text-[9px]
          w-24
          bg-[#67deb6]
          text-white
          rounded-tl-[5px]
          py-[1px]
          px-[10px]
          font-semibold
          flex
          items-center
          justify-center
        "
      >
        Completed
      </span>
      <span
        v-if="status == 'pending_reward'"
        class="
          text-[9px]
          w-24
          bg-[#F6A42C]
          text-white
          rounded-tl-[5px]
          py-[1px]
          px-[10px]
          font-semibold
          flex
          items-center
          justify-center
        "
      >Pending Reward</span>
      <span
        v-if="status == 'failed' || status == 'expired'"
        class="
          text-[9px]
          w-24
          bg-[#c90000]
          text-white
          rounded-tl-[5px]
          py-[1px]
          px-[10px]
          font-semibold
          flex
          items-center
          justify-center
        "
      >
        Failed
      </span>

      <span
        v-if="status == 'in_progress'"
        class="
          text-[9px]
          w-24
          bg-[#5F5F5F]
          text-white
          rounded-tl-[5px]
          py-[1px]
          px-[10px]
          font-semibold
          flex
          items-center
          justify-center
        "
      >
        In Progress
      </span>
    </div>
  </div>

  <!-- <div
    v-if="currentDayMethod == startDate"
    class="missionCard"
    :class="cardStatusColor"
  >
    <div class="missionCardMask">
      <div class="missionCardContent">
        <img
          v-if="status == 'pending_reward'"
          src="../assets/images/coinTrans.gif"
          alt="Pending reward"
          style="width: 70px; height: 67px"
        />
        <img
          v-else-if="status == 'in_progress'"
          src="https://img.icons8.com/bubbles/2x/task.png"
          style="width: 70px; height: 67px"
          alt="In progress"
        />
        <img
          v-else
          src="@/assets/images/checkmark.svg"
          style="width: 70px; height: 67px"
          alt="In progress"
        />
        <h1>{{ title }}</h1>
        <p>{{ description }}</p>
        <div style="margin-top: 20px">
          <div v-if="status == 'completed'">You received</div>
          <div v-if="status == 'pending_reward'">You will receive</div>
          <h2 v-if="type == 'money'">&pound;{{ formatPrice(price) }}</h2>
          <h2 v-else-if="type == 'points'">
            {{ activeSumPoints }}
            <div class="missionPointsTitle">Sonik Points</div>
          </h2>
          <h2 v-else-if="type == 'wishlist'" style="font-size: 20px">
            Wishlist
            <div class="missionPointsTitle" style="margin-top: 0">
              Product Reward
            </div>
          </h2>
          <h2 v-else>&pound;{{ formatPrice(price) }}</h2>
        </div>
      </div>
      <div class="extra">
        <div v-if="status == 'in_progress'" class="rainbowGradient">
          <div class="innerSquare">
            <span @click="openMissionDetailsModal"> View Details </span>
          </div>
        </div>
        <div v-else class="rainbowGradient" style="background: #7a615a">
          <div class="innerSquare" style="background: #7a615a">
            <span @click="openMissionDetailsModal"> View Details </span>
          </div>
        </div>
      </div>
    </div>

    <AppModal :is-open="missionDetailsModal" @close="closeMissionDetailsModal">
      <AppMissionDetails
        :title="title"
        :description="description"
        :state="headingString"
        :missionState="state"
        :timeleft="timeleft"
        :price="price"
        :headingColor="headingColor"
        :message="message"
        :rewardType="type"
        :isRewardVisible="rewardVisible"
        :product="wishlistProduct"
      />
      <AppForm
        v-if="status == 'in_progress'"
        @submit="setMssionStatusAsDone(missionId)"
      >
        <AppButton :mini="true" color="red" style="width: 300px">
          I did it
        </AppButton>
      </AppForm>
    </AppModal> -->
  <!-- </div> -->
</template>

<script>
  // import AppModal from "@/components/AppModal";
  // import AppForm from "@/components/AppForm";
  // import AppButton from "@/components/AppButton";
  // import AppMissionDetails from "@/components/AppMissionDetails";
  import { mapActions, mapGetters } from "vuex";

  export default {
    components: {
      // AppModal,
      // AppForm,
      // AppButton,
      // AppMissionDetails,
      // countdown,
    },
    props: {
      missionId: {
        type: Number,
        default: 0,
      },
      wishlistProduct: {
        type: Object,
      },
      rewardVisible: {
        type: Boolean,
      },
      timeleft: {
        type: Number,
        default: 0,
      },
      title: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      state: {
        type: String,
        default: "",
      },
      status: {
        type: String,
      },
      missionState: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: "",
      },
      startDate: {
        type: String,
      },
      price: {
        // type: Number,
        // default: 0
      },
      activeSumPoints: {
        // type: Number,
        // default: 0
      },
      statusText: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        missionDetailsModal: false,
        timeleftCopy: 0,
      };
    },
    computed: {
      ...mapGetters(["childName", "getMissionDayParamGetter"]),
      headingString() {
        if (this.state === "in progress") {
          if (this.timeleftCopy > 0) {
            let hours = Math.floor(this.timeleftCopy / 60 / 60);
            let minutes = Math.floor(this.timeleftCopy / 60) - hours * 60;
            let seconds = Math.floor(this.timeleftCopy % 60);
            return hours > 0
              ? `Due in ${hours}h ${minutes}m ${seconds}s`
              : `Due in ${minutes}m ${seconds}s`;
          } else {
            return "No time limit";
          }
        } else if (this.state === "pending_reward") return "Awating confirmation";
        else if (this.status === "completed") return "Completed";
        else if (this.status === "failed") return "Failed";
        else if (this.status === "expired") return "Expired";
        return "Error, please contact the developers!";
      },
      headingColor() {
        if (this.status === "in_progress") {
          if (this.timeleftCopy < 1800) return "#FF4F79";
          else return "#7A615A";
        } else if (this.state === "pending_reward") {
          return "#36D5F2";
        }
        return "#FF4F79";
        // else if(this.state === 'failed')
        //     return '#FF4F79';
        // else if(this.state === 'expired')
        //     return '#36D5F2';
        // return '#FF4F79';
      },
      cardColor() {
        if (this.state === "in progress") {
          if (this.timeleftCopy > 0) {
            return "underBlue";
          }
        } else if (this.status === "pending_reward") return "underOrange";
        else if (this.status === "completed") return "underGreen";
        else if (this.status === "failed") return "underRed";
        else if (this.status === "expired") return "underBlack";
        return "underBlue";
      },
      message() {
        if (this.status == "failed") return "You would have received";
        if (this.status === "in_progress") {
          return "You will receive";
        } else if (this.status === "pending_reward") return "You will receive";
        else if (this.status === "completed") return "You received";
        else return "You would have received";
      },
      currentDayMethod() {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        return [day, month, year].join("-");
      },
      cardStatusColor() {
        if (this.status == "pending_reward") {
          return "pendingCard";
        } else if (this.status == "completed") {
          return "completedCard";
        } else {
          return "";
        }
      },
    },
    methods: {
      ...mapActions(["editMssionStatus"]),
      openMissionDetailsModal() {
        this.missionDetailsModal = true;
      },
      closeMissionDetailsModal() {
        this.missionDetailsModal = false;
      },
      setMssionStatusAsDone(missionId) {
        const payload = {
          date: this.getMissionDayParamGetter,
          missionId: missionId
        };
        this.editMssionStatus(payload);
        this.missionDetailsModal = false;
      },
      countDownTimer() {
        if (this.timeleftCopy > 0) {
          setTimeout(() => {
            (this.timeleftCopy -= 1), this.countDownTimer();
          }, 1000);
        }
      },
    },
    created() {
      this.timeleftCopy = this.timeleft;
      this.countDownTimer();

      // console.log("object wishlist");
      // console.log(this.rewardVisible);
      // console.log(this.missionId);
    },
  };
</script>

<style lang="scss" scoped>
  .activity_item {
    width: 100%;
    height: auto !important;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    background: #F5F5F5 !important;
    padding: 25px !important;
    border-radius: 30px !important;
    border-bottom-right-radius: 15px;
    position: relative;
    overflow: hidden;
    transition: all 200ms ease-in-out !important;

    &:active {
      opacity: 0.6;
    }

    &__container {
      display: flex;
      align-items: center;
    }

    &__title {
      font-weight: 800;
      font-size: 20px;
      letter-spacing: 0.07rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 110px;
    }

    &__description {
      font-size: 18px;
      color: #9b9b9b;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 185px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &__amount {
      position: absolute;
      top: 10px;
      right: 15px;
      color: var(--main);
      font-weight: 600;
      font-size: 11px;
    }
    &__name{
      color: #9E9E9E;
      font-weight: 800;
      font-size: 25px;
    }

    // &__icon {
    //   margin-right: 15px;
    //   display: flex;
    //   align-items: center;

    //   img {
    //     width: 40px;
    //   }
    // }
  }
</style>
